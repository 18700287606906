import Vue from 'vue' // Импортируем Vue
import store from '@/store/index' // Импортируем Store
import axios from '@/plugins/axios'
import PortalVue from 'portal-vue'
import bus from '@/plugins/bus'
import VueCookie from 'vue-cookie'
import { gsap, ScrollTrigger, ScrollToPlugin } from 'gsap/all'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

Vue.use(VueCookie)
Vue.use(PortalVue)
Vue.config.productionTip = false // Отключаем предупреждение о режиме разработки

// Подключаем глобально стили
import '@/assets/tailwind.css'
import '@/assets/css/animate.css'
// Подключаем Axios
Vue.prototype.$axios = axios

// Создаем event bus
Vue.prototype.$bus = bus

// Вшиваем в this Store
Vue.prototype.$store = store

export default Vue
