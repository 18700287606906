<template>
  <div class="layout">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.layout {
}
</style>
