import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    productParams: window.__productParams__,
    lang: 0,
    isMobile: false,
    globalMenuOpened: false,
    languages: [
      {
        name: 'USA',
        icon: 'usa.png'
      },
      {
        name: 'Schweiz',
        icon: 'switz.png'
      },
      {
        name: 'Australia',
        icon: 'australia.png'
      },
      {
        name: 'New Zealand',
        icon: 'newzeeland.png'
      },
      {
        name: 'Deutschland',
        icon: 'germany.png'
      },
      {
        name: 'Österreich',
        icon: 'austria.png'
      },
      {
        name: 'England',
        icon: 'england.png'
      },
      {
        name: 'Canada',
        icon: 'canada.png'
      },
      {
        name: 'الإمارات العربية المتحدة',
        icon: 'emirates.png'
      },
      {
        name: 'المملكة العربية السعودية‎',
        icon: 'arabia.png'
      }
    ]
  },
  mutations: {},
  actions: {},
  modules: {}
})
