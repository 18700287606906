<template>
  <div class="layout-header" :class="[{ 'is-active-search': isSearch }]" v-click-outside="closeSearch">
    <div class="top">
      <ul class="hidden menu lg:flex">
        <slot name="menu"></slot>
      </ul>
      <div class="city">
        <icon-map></icon-map>
        <div class="s-14 w-500">Москва</div>
        <!--  -->
        <div class="cities-popup ui-scroll">
          <div
            :class="[{ active: city === cityName }]"
            class="city-name s-14 w-500"
            v-for="(cityName, index) in cities"
            :key="index"
            @click="changeCity(cityName)"
          >
            {{ cityName }}
          </div>
        </div>
      </div>
      <div class="s-14 w-500 cities">Адреса магазинов</div>
    </div>
    <div class="main">
      <a href="/" class="logo-wrapper">
        <ui-logo class="logo"></ui-logo>
      </a>
      <div class="search-button button" :class="[{ active: isSearch }, { 'pretty-button': !isSearch }]">
        <input
          @keypress.enter="goToSearchResults"
          @input="onInputSearch"
          v-model.trim="searchQuery"
          placeholder="Начните искать..."
          autofocus
          type="text"
          v-if="isSearch"
        />
        <icon-search @click.native="openSearch"></icon-search>
        <layout-search-suggestions-window
          :closeSearch="closeSearch"
          :searchQuery="searchQuery"
          :onSelectSuggestion="onSelectSuggestion"
          :suggestions="suggestions"
          :products="products"
          v-if="isSearch && searchQuery && (suggestions.length || products.length)"
        ></layout-search-suggestions-window>
      </div>
      <layout-main-menu v-if="!isSearch" @close="isMenuOpen = false" :class="[{ open: isMenuOpen }]">
        <slot name="main-menu"></slot>
      </layout-main-menu>
      <slot v-if="isSearch && isMobile ? false : true" name="buttons"></slot>
      <div v-if="isSearch && isMobile ? false : true" @click="isMenuOpen = true" class="menu-button">
        <icon-menu></icon-menu>
      </div>
    </div>
    <!--  -->
    <!-- <layout-header-cities-popup v-if="citiesPopupOpened"></layout-header-cities-popup> -->
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  props: {
    city: {
      type: String,
      default() {
        return 'Москва'
      }
    }
  },
  data() {
    return {
      isMenuOpen: false,
      isSearch: false,
      searchQuery: '',
      suggestions: [],
      products: [],
      isMobile: false,
      citiesPopupOpened: true,
      cities: window.__cities__
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted() {
    this.popupItem = this.$el
    this.isMobile = window.innerWidth < 768
  },
  components: {
    iconMap: () => import('@/components/icon/icon-map'),
    iconMenu: () => import('@/components/icon/icon-menu'),
    iconSearch: () => import('@/components/icon/icon-search'),
    iconBag: () => import('@/components/icon/icon-bag'),
    uiLogo: () => import('@/components/ui/ui-logo'),
    layoutMainMenu: () => import('@/components/layout/layout-main-menu'),
    layoutSearchSuggestionsWindow: () => import('@/components/layout/search/layout-search-suggestions-window'),
    layoutHeaderCitiesPopup: () => import('@/components/layout/header/layout-header-cities-popup')
  },
  methods: {
    openSearch() {
      if (this.isSearch) {
        return
      }
      this.isSearch = true
      setTimeout(() => {
        this.$el.querySelector('.search-button input').focus()
      }, 500)
    },
    closeSearch() {
      this.isSearch = false
      this.searchQuery = ''
      this.suggestions = []
      this.products = []
    },
    async onInputSearch() {
      const { products, suggestions } = await this.fakeServer()
      this.products = products
      this.suggestions = suggestions
    },
    onSelectSuggestion($event) {
      this.searchQuery = $event
      this.goToSearchResults()
    },
    goToSearchResults() {
      window.location.href = `/search?q=${this.searchQuery}`
    },
    changeCity(cityName) {
      // logic
      window.location.href = `/${cityName}`
    },
    fakeServer() {
      // Ограничнить вывод максимум 6ю товарами
      return new Promise((resolve, reject) => {
        const fakeProducts = require('./search/products.json')
        const answer = {
          suggestions: [
            'Askona Smart Balance',
            'Askona Another Mattress',
            'Askona Sofa',
            'Askona Chair',
            'Askona Pillows'
          ],
          products: fakeProducts
        }
        resolve(answer)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .post-page & {
    position: relative;
  }
}
.top {
  height: 3.125em;
  background: var(--accent);
  color: #fff;
  padding: 0 var(--gap);
  display: flex;
  align-items: center;
}
.logo-wrapper {
  display: flex;
  align-items: center;
  svg {
    width: 120px;
    height: auto;
    position: relative;
    top: 2px;
  }
}
.menu {
  margin-right: auto;
  padding: 0;
  list-style: none;
  align-self: stretch;
  align-items: stretch;
  & > * {
    margin-right: 1.25em;
    display: inline-block;
    position: relative;
    display: flex;
    align-items: center;
    svg {
      margin-left: 0.5em;
    }
    &:hover > ul {
      display: block;
    }
    ul {
      position: absolute;
      background: #fff;
      color: var(--accent);
      top: 100%;
      left: 0;
      white-space: nowrap;
      list-style: none;
      border-radius: 5px;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      display: none;
      z-index: 100;
      a {
        padding: 0.5em 1em;
        display: block;
        transition: 0.4s;
        &:hover {
          background: var(--light);
        }
      }
    }
  }
}
.city {
  margin-right: 1em;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  svg {
    margin-right: 0.5em;
  }
  &:hover {
    .cities-popup {
      transform: none;
      opacity: 1;
      visibility: visible;
    }
  }
}
.cities-popup {
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  background: #fff;
  white-space: nowrap;
  position: absolute;
  z-index: 30;
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: var(--color);
  max-height: 300px;
  transform: translate3d(0, 10px, 0);
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
  .city-name {
    padding: 8px 15px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background: var(--light);
    }
    &.active {
      background: var(--color);
      color: #fff;
    }
  }
}
.main {
  padding: 0 var(--gap);
  height: 5em;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 11px 34px rgba(0, 0, 0, 0.06);
}
.menu-button,
.logo {
  margin-right: 1.4vw;
  cursor: pointer;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 11vw;
  height: auto;
}

.cart-button,
.fav-button,
.side-button {
  margin-left: 1.5vw;
}

.menu-button {
  display: none;
}

.search-button {
  input {
    width: 100%;
    height: 100%;
    background: none;
    font-size: 15px;
    font-weight: 500;
    font-family: inherit;
    padding: 0 20px;
  }
  &.active {
    background: var(--light);
    flex-grow: 1;
    height: 50px;
    border-radius: 7px;
    padding-right: 20px;
    position: relative;
  }
}

@media screen and (max-width: 1380px) {
  .top {
    height: 2em;
  }
  .main {
    height: 3em;
  }
  .cart-button,
  .side-button,
  .fav-button,
  .search-button {
    svg {
      height: 18px;
      width: auto;
    }
  }
}

@media screen and (max-width: 1170px) {
  .menu-button {
    margin-left: 0.8em;
    display: block;
    svg {
      position: relative;
      top: 2px;
    }
  }
  .search-button {
    margin-right: auto;
  }
}

@media screen and (max-width: 920px) {
  .main-menu {
    display: none;
  }
  .search-button {
    margin-left: auto;
  }
  .main {
    height: 4em;
  }
}
.cities {
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .cities {
    margin-left: auto;
  }
  .logo {
    margin-left: 0.5em;
  }
  .logo-wrapper {
    svg {
      width: 8em;
    }
  }
  .cart-button,
  .fav-button,
  .side-button {
    margin-left: 1em;
  }
  .search-button {
    margin: unset;
    margin-left: auto;
  }
  .layout-header {
    &.is-active-search {
      .logo,
      .buttons {
        display: none !important;
      }
    }
  }
}
</style>
